import React, { Fragment, useState } from 'react';
import { Menu, Grid, HelpCircle, XCircle, LogOut } from 'react-feather';
import { TERMS_VERSION, getAppTokenName, getUrl, APPS  } from '@axeedge/go-shared-utils';
import { Link } from '@reach/router';
import cx from 'classnames';
import logo from '../../images/boomer.png'
import profileDefault from '../../images/profile-default.svg';
import styles from './Nav.module.scss';
import ls from 'local-storage';
const Nav = ({ user, source = 'WRITING', onLogout}) => {

    const [showMenu, setShowMenu] = useState(false)

    const isActive = ({ isCurrent }) => {
      return isCurrent ? { className: cx(styles.subHeading, styles.subHeadingActive) } : { className: styles.subHeading }
    }

    const getAppName = () => {
        if (source === 'PORTAL') {
          if (ls('country') === 'US') {
            return APPS.boom_math_portal_teacher;
          } else {
            return APPS.go_portal_teacher;
          }
        }
        if (source === 'WRITING') {
          return APPS.go_write_teacher;
        }
        if (source === 'READING') {
          return APPS.go_read_teacher;
        }
        if (source === 'MATHS') {
          return APPS.boom_math_teacher;
        }
        return null;
      };
    
      const token = ls(getAppTokenName(getAppName()));

    return (
        <>
          <div className={styles.mobNav} onClick={() => setShowMenu(!showMenu)}>
          <span className={styles.mobNavGrid}>
            W
          </span>
          <span className={styles.mobNavMenu}>
            <span className="u-m-right-1">MENU</span>
            <Menu size="20" />
          </span>
        </div>
      <div className={styles.nav}>
        {parseInt(user.lastTermsVersionApproved) >= TERMS_VERSION &&
          user.school && (
            <ul className={styles.navMenu}>
              <li>
                <Link
                  to='/'
                  className={styles.navMenuLink}
                  onClick={() => setShowMenu(false)}
                >
                  <span className={styles.letterIcon}>W</span>
                </Link>
              </li>
              <span className={styles.navMenuAppText}>WRITER</span>
              <li>
                <span
                  onClick={() => setShowMenu(!showMenu)}
                  className={cx(styles.navMenuBtn, 'menuBtn')}
                >
                  <Menu size="24" />
                  <span className={styles.navMenuBtnText}>MENU</span>
                </span>
              </li>
            </ul>
          )}
        <div className={styles.navExit}>
          <Link to="/" className={styles.navBrand}>
            <img src={logo} />
          </Link>
        </div>
      </div>
      {showMenu && (
        <div
          className={cx(styles.sideOverlay, 'sideOverlay')}
          onClick={(event) => {
            if (event.target.classList.contains('sideOverlay')) {
              setShowMenu(false);
            }
          }}
        >
          <div className={styles.sideMenu}>
            <a
              href={`${getUrl(APPS.go_portal_teacher)}/profile`}
              onClick={() => {
                setShowMenu(false);
              }}
              className={styles.profileLink}
            >
              <img
                className={styles.profileImg}
                src={user.avatarUrl || profileDefault}
              />
              <p className={styles.profileName}>{user.displayName}<span className={styles.profileManage}>Manage Profile</span></p>
            </a>
            <div className={styles.sideLinks}>
                <a
                  href={getUrl(APPS.go_portal_teacher)}
                  className={styles.appLink}
                >
                  <span className={styles.hubIcon}><Grid size="16"/></span>
                  School Hub
                </a>
                <>
                  {user.school.canAccessGoReadApp ? (
                    <a
                      href={`${getUrl(APPS.go_read_teacher)}${
                        ls('go_impersonating') ? '/impersonate' : ''
                        }?token=${token}&schoolId=${user.school.id}`}
                      className={styles.appLink}
                    >
                      <span className={styles.appLetter}>R</span>
                      Reader
                    </a>
                  ) : (
                    <span className={styles.appLink}><span className={styles.appLetter}>R</span>Reader - Locked</span>
                  )}
                    <Link to="/" getProps={isActive} onClick={() => setShowMenu(false)}> <span className={styles.appIcon}>W</span>Writer</Link>
                </>
              <div className={styles.endSet}>
              <a
                  onClick={() => setShowMenu(false)}
                  href="https://help.boomhub.app/"
                  target="_blank"
                  className={styles.endLink}
                >
                  <HelpCircle className={styles.endLinkIcon} size="16" />
                  Help &amp; Guides
                </a>
                <span
                  onClick={() => onLogout()}
                  className={styles.endLink}
                >
                  <LogOut className={styles.endLinkIcon} size="16" />
                  Logout
                </span>
              </div>
            </div>
            <XCircle className={styles.closeBtn} size={32} onClick={() => setShowMenu(false)}/>
          </div>
        </div>
      )}
      </>
    );
}

export default Nav;
