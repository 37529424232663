import React, { Fragment } from 'react';
import { BOOK_ORDER_TYPE } from '../../../../../../services/utils/constants';
import CreateOrder from '../CreateOrder';
import ViewOrder from '../ViewOrder';

const IsPurchased = ({ book }) => {

    const classOrder = book.printBookOrders.find(order => order.orderType === BOOK_ORDER_TYPE.class);

    return(
        <Fragment>
            {
                classOrder ? <ViewOrder classOrder={classOrder} /> : <CreateOrder book={book}  />
            }
        </Fragment>
         
    )
}

export default IsPurchased;