import React, { Fragment, useState, useContext, useEffect } from 'react';
import { BOOK_ORDER_TYPE } from '../../../../../../services/utils/constants';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { getDefaultOrderDetails, setOrderDetailsJson }  from '../../../../services/utils';
import { AuthContext } from '../../../../../../services/auth/AuthProvider';
import { Loader } from '@axeedge/go-teacher-components';

import PrepareForPrint from '../../components/PrepareForPrint';
import EditOrder from '../../components/EditOrder';

import { GET_CLASS_QUERY } from '../../../../../../services/class/graphql';
import { GET_CLASS_BOOK, ADD_PRINT_BOOK_ORDER } from '../../../../services/graphql';

const CreateOrder = ({ book }) => {

    const { currentUser } = useContext(AuthContext);
    const { school } = currentUser;

    const [reviewEditOrder, setReviewEditOrder] = useState(false);
    const [orderEdited, setOrderEdited] = useState();
    const [formErrors, setFormErrors] = useState([]);
    const [orderCopies, setOrderCopies] = useState();
    const [confirmPrint, setConfirmPrint] = useState();

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: book.studentsClass.id
        }
    });

    useEffect(() => {
        if (data && data.studentsClass) {
            setOrderCopies(getDefaultOrderDetails(data.studentsClass.students))
        }
    }, [data])

    const [addPrintBookOrder] = useMutation(ADD_PRINT_BOOK_ORDER, {
        onCompleted: ({ addPrintBookOrder }) => {
            setConfirmPrint(false);           
            if (addPrintBookOrder.errors && addPrintBookOrder.errors.length !== 0) {
                setFormErrors(addPrintBookOrder.errors);
                return;
            }
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: book.id }})
    });

    const onPrintOrder = () => {
        addPrintBookOrder({
            variables: {
                classBookId: book.id,
                orderType: BOOK_ORDER_TYPE.class,
                orderDetails: setOrderDetailsJson(orderCopies),
                shippingToStreet: school.address,
                shippingToAddress2: school.address2,
                shippingToCity: school.city,
                shippingToPostcode: school.zip 
            }
        });
    }


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass) {
        return(
            <Fragment>
                {
                    !reviewEditOrder ? (
                        <PrepareForPrint
                            book={book}
                            setReviewEditOrder={setReviewEditOrder}
                            orderEdited={orderEdited}
                            formErrors={formErrors}
                            confirmPrint={confirmPrint}
                            setConfirmPrint={setConfirmPrint}
                            onPrintOrder={onPrintOrder}
                        />
                    ) : (
                        <EditOrder
                            book={book}
                            setReviewEditOrder={setReviewEditOrder}
                            teacherName={currentUser.name}
                            school={school}
                            setOrderEdited={setOrderEdited}
                            orderCopies={orderCopies}
                            setOrderCopies={setOrderCopies}
                        />
                    )
                }
            </Fragment>
        )
    }


    return null
}

export default CreateOrder;