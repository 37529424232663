import React, { Fragment, useState } from 'react';
import { Copy } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BOOK_ORDER_STATUS } from '../../../../../../services/utils/constants';
import moment from 'moment';

const ViewOrder = ({ classOrder }) => {

    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const renderContent = () => {
        switch (classOrder.status) {
            case BOOK_ORDER_STATUS.new:
                return(
                    <Fragment>
                        <h2 className='heavy u-m-base-2'>Your order has been placed!</h2>
                        <p>We'll notify you when your books are printed</p>
                    </Fragment>
                )
            case BOOK_ORDER_STATUS.to_printer:
                return(
                    <Fragment>
                            <h1 className='heavy u-m-base-2'>Your books are being printed!</h1>
                            <p className='u-m-base-2'>We'll notify you when your book ships along with passing on shipment tracking details.</p>
                    </Fragment>
                );
            case BOOK_ORDER_STATUS.delivered:
                return(
                    <Fragment>
                        <h1 className='heavy u-m-base-2'>Your books have shipped!</h1>
                        <h2 className='u-m-base-3'><span className='heavy'>Shipped:</span> {moment(new Date(classOrder.sendToDestinationAt)).format('MM/DD/YYYY')}</h2>
                        {classOrder.trackingNumber && <h2 className='u-m-base-3'><span className='heavy'>Tracking Number:</span> {classOrder.trackingNumber} <CopyToClipboard onCopy={() => setCopiedToClipboard(true)} text={classOrder.trackingNumber}><Copy className='link u-text-tertiary' /></CopyToClipboard></h2>}
                        {copiedToClipboard && <p className='small'>Tracking number copied to clipboard</p>}
                    </Fragment>
                )
            default:
                return '-';
        }
    }

    return(
        <Fragment>
            {renderContent()}
        </Fragment>
    )
}

export default ViewOrder;